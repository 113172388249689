<template>
  <section>
    
    <!--工具条-->
    <toolbar :buttonList="buttonList"
             @callFunction="callFunction"
             :buttonListmsg="buttonListmsg"></toolbar>
    <!--列表内容-->
    <el-table :data="meetings"
              id="mytable"
              ref="multipleTable"
              highlight-current-row
              @current-change="selectCurrentRow"
              row-key="Id"
              lazy
              :indent="30"
              :load="load"
              :tree-props="{children: 'children',hasChildren: 'IsHasChildren'}"
              :fit="true"
              style="width: 100%"
              :row-style="{height:'60px'}"
              :cell-style="{padding:'0px'}"
              :header-cell-style="{background:'#dce4f1',color:'#000000'}"
              @cell-mouse-enter="tabmouseEnter"
              @cell-mouse-leave="tabmouseLeave"
              @row-dblclick="checkInfo">
      <!-- <el-table-column type="index"></el-table-column> -->
      <el-table-column type="index" width="6">
        <template scope="scope">
        </template>
      </el-table-column>
      <el-table-column label="专项会议名称"
                       prop="MeetingName"
                       show-overflow-tooltip
                       width="370px">
        <template slot-scope="scope">
          <i class="el-icon-chat-dot-round"
             v-if="scope.row.IsHasChildren == true"></i>
          <i class="el-icon-chat-dot-roundxxx"
             v-if="scope.row.IsHasChildren == false && scope.row.ParentId != null"></i>
          {{scope.row.MeetingName}}
        </template>
      </el-table-column>
      <el-table-column label="工作项目" prop="WorkItem" min-width="270px">
        <template slot-scope="scope">
          <el-button @click="handleCollect(scope.row)" style="margin-right:3px;color:#FF9800;" size="mini" v-show="scope.row.PersonOfDuty!='' && (collectBtn&&collectRowId==scope.row.Id) || scope.row.CollectId!=null"  :title="scope.row.CollectId==null?'特别关注':'已特别关注'"  type="default" :icon="scope.row.CollectId==null?'el-icon-star-off':'el-icon-star-on'" circle></el-button><span :style="{'cursor':'default'}">{{scope.row.WorkItem}}</span>
        </template>
      </el-table-column>
      <el-table-column label="计划完成时间"
                       prop="PlanComplateTime"
                       :formatter="formatPlanComplateTime"
                       min-width="120px">
        <template slot-scope="scope">
          <div v-if="new Date()>new Date(new Date(new Date(scope.row.PlanComplateTime).toLocaleDateString()).getTime()+24*60*60*1000-1)&&scope.row.TaskProgressValue<100">
            <font color=red>{{formatPlanComplateTime(scope.row)}}</font>
          </div>
          <div v-else>
            <font color=black>{{formatPlanComplateTime(scope.row)}}</font>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="责任人"
                       prop="PersonOfDuty"
                       min-width="70px"></el-table-column>
      <el-table-column label="任务状态"
                       prop="Status"
                       align="center"
                       min-width="100px">
        <template slot-scope="scope">
          
            <div>
              <div v-if="scope.row.TaskProgressValue<100"  style="font-size:10px;">进度:{{formatProgress(scope.row)}}</div>
              <el-tag v-if="scope.row.Status == 0"
                      effect="dark"> 进行中</el-tag>
              <el-tag v-if="scope.row.Status == 1"
                      effect="dark"
                      type="warning">已完成审核中</el-tag>
              <el-tag v-if="scope.row.Status == 2"
                      effect="dark"
                      type="success">已完成</el-tag>
              <el-tag v-if="scope.row.Status == -1"
                      effect="dark"
                      type="info">已取消</el-tag>
              <el-tag v-if="scope.row.Status == 3"
                      effect="dark"
                      type="danger">退回</el-tag>
              <el-tag v-if="scope.row.Status == 4"
                      effect="dark"
                      type="warning">新建任务待审核</el-tag>
              <el-tag v-if="scope.row.Status == 5"
                      effect="dark"
                      type="danger">取消任务待审核</el-tag>
              <el-tag v-if="scope.row.Status == 6"
                      effect="dark"
                      type="danger">新建任务退回</el-tag>
            </div>
        </template>
      </el-table-column>
      <!-- <el-table-column label="进度"
                       prop="TaskProgressValue"
                       :formatter="formatProgress"
                       align="left"
                       min-width="55px"></el-table-column> -->
      <!-- <el-table-column label="任务类型"
                       prop="CommonTaskClassifyText"
                       min-width="100px"
                       :formatter="formatCommonTaskClassifyText"></el-table-column> -->
      <!-- <el-table-column label="任务来源" prop="TaskStatus"  min-width="100px"></el-table-column> -->
      <el-table-column label="创建时间"
                       prop="CreateTime"
                       min-width="100px"
                       :formatter="formatCreateTime">
        <template slot-scope="scope">
          <el-tooltip placement="top">
            <div slot="content">
              {{ formatTipCreateTime( scope.row) }}
            </div>
            <div>
              {{ formatCreateTime( scope.row) }}
            </div>
          </el-tooltip>
        </template>
      </el-table-column>
      <!-- <el-table-column label="完成后需审核" prop="IsNeedApproval" min-width="110px">
                    <template slot-scope = "scope">
                    <el-tag :type = "scope.row.IsNeedApproval ? 'danger' : 'success'"  disable-transitions>
                        {{scope.row.IsNeedApproval ? "是" : "否"}}
                    </el-tag>
                    </template>
                </el-table-column> -->
      <el-table-column label="操作"
                       min-width="70px">
        <template slot-scope="scope">
          <el-tooltip v-if="localuser == scope.row.PersonOfDuty"
                      placement="top"
                      content="双击可以维护任务进度喔~"  :disabled="false">
            <el-row>
              <el-button type="text"
                         size="medium"
                         @click="checkInfo(scope.row)">维护</el-button>
              <el-button type="text"
                         v-if="scope.row.Status == 3 || scope.row.Status == 6"
                         style="color: red;"
                         @click="readdSubmit(scope.row)">重新提交</el-button>
            </el-row>
          </el-tooltip>
          <el-tooltip v-else
                      placement="left"
                      v-show="scope.row.PersonOfDuty!=''"
                      content="双击也可以查看任务进度哦~" :disabled="false">
            <el-row>
              <el-button type="text"
                         size="medium"
                         @click="checkInfo(scope.row)">查看</el-button>
              <el-button type="text"
                         v-if="scope.row.Status == 3 || scope.row.Status == 6"
                         style="color: red;"
                         @click="readdSubmit(scope.row)">重新提交</el-button>
            </el-row>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页器 -->
    <el-pagination align='center'
                   @current-change="handleCurrentChange"
                   :current-page="currentPage"
                   :page-size="pageSize"
                   layout="prev, pager, next, jumper"
                   :page-count="total"
                   :total="totaldata">
    </el-pagination>
    <!--新增界面-->
    <el-dialog :visible.sync="addFormVisible"
               v-model="addFormVisible"
               :close-on-press-escape="false"
               :close-on-click-modal="false"
               @close="clickclose"
               center>
      <template slot="title">
        <span style="color:#FFFFFF;font-size: 16px;">新增专项会议</span>
      </template>
      <el-form :model="addForm"
               label-width="85px"
               :rules="addFormRules"
               ref="addForm"
               style="white-space:nowrap;">
        <el-row>
          <el-form-item label="会议名称:"
                        prop="MeetingName">
            <el-input v-model="addForm.MeetingName"
                      maxlength="30"
                      show-word-limit
                      auto-complete="off"
                      placeholder="请填写专项会议名称"
                      @click.native="meetingNameblur"
                      ref="meetinginput"></el-input>
          </el-form-item>
        </el-row>
        <el-row>
          <el-col :span="12">
            <div class="history"
                 v-show="showhistory"
                 style="margin-top: -15px;margin-left: 20%; word-break: break-all;white-space: normal;">
              <span class="record"
                    v-for="(historyItem, index) in historyList"
                    :key="index"
                    style="font-size:15px;"
                    @click="historydata(historyItem)">
                <el-tag>
                  {{historyItem}}
                </el-tag>
              </span>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="content"
                 style="margin-top: -15px;margin-left: 20%;"
                 v-show="showhistory">
              <span class="cancel"
                    @click="clearHistory"
                    style="font-size:12px; margin-top:170px;cursor: pointer;color:red;margin-left:-20%;">清空历史记录</span>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24"
                  style="left: 3%;">
            <el-row>
              <el-col :span="0.1"
                      style="margin-top:10px">
                <span style="color: red;">*</span>
              </el-col>
              <el-col :span="9">
                <el-form-item label="指令人:"
                              prop="InstructUserName">
                  <el-input v-model="addForm.InstructUserName"
                            readonly
                            placeholder="请选择指令人">{{orderneedname}}</el-input>
                </el-form-item>
              </el-col>
              <el-col :span="2">
                <el-button type="text">
                  <userchoosetool style="margin-top:-1px"
                                  @ordercallFunction="ordercallFunction"
                                  :callbackmsg="orderpeo"></userchoosetool>
                </el-button>
              </el-col>
              <el-col :span="9">
                <el-form-item label="抄送人员:"
                              prop="CCUserName">
                  <el-input v-model="addForm.CCUserName"
                            readonly
                            placeholder="请选择抄送人员">{{ccusername}}</el-input>
                  <el-button type="text"
                             size="mini"
                             @click="handleChooseCCUser">选择人员</el-button>
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
        <el-form :inline="true">
          <el-button type="goon"
                     size="mini"
                     icon="el-icon-plus"
                     @click="insertmeeting">新增</el-button>
        </el-form>
        <el-table :data="meetingList"
                  height="300px"
                  :row-class-name="MultitableRowClassName"
                  :row-style="{height:'40px'}"
                  :cell-style="{padding:'0px'}"
                  :header-cell-style="{background:'#dce4f1',color:'#000000'}"
                  :fit="true">
          <el-table-column type="index"
                           min-width="50px"></el-table-column>
          <el-table-column label="工作项目"
                           prop="WorkItem"
                           min-width="100px"
                           show-overflow-tooltip></el-table-column>
          <!-- <el-table-column label="工作描述"
                           prop="WorkDescription"
                           min-width="100px"
                           show-overflow-tooltip></el-table-column> -->
          <el-table-column label="计划完成时间"
                           prop="PlanComplateTime"
                           min-width="130px"></el-table-column>
          <el-table-column label="责任人"
                           prop="PersonOfDuty"
                           min-width="100px"></el-table-column>
          <el-table-column label="备注"
                           prop="Remark"
                           min-width="90px"
                           show-overflow-tooltip></el-table-column>
          <el-table-column label="附件"
                           prop="FileName"
                           min-width="90px"
                           show-overflow-tooltip></el-table-column>
          <el-table-column label="操作" min-width="90px">
            <template slot-scope="scope">
              <el-button size="mini" type="text" @click="editmeeting(scope.$index,scope.row)">编辑</el-button>
              <el-button size="mini"
                         type="text"
                         @click="deletemeeting(scope.row)">删除</el-button>
            </template>
          </el-table-column>
          <!-- <el-table-column label="年度计划"
                           prop="Tag"
                           min-width="100px"
                           show-overflow-tooltip>
            <template slot-scope="scope">
              <span>{{scope.row.TagName}}</span>
            </template>
          </el-table-column> -->
        </el-table>
      </el-form>
      <div slot="footer"
           class="dialog-footer">
        <el-button type="goon"
                   @click="addSubmit">提交</el-button>
      </div>
    </el-dialog>
    <!--专项会议多行添加-->
    <el-dialog :visible.sync="addMultiFormVisible"
               v-model="addMultiFormVisible"
               :close-on-press-escape="false"
               :close-on-click-modal="false"
               center>
      <template slot="title">
        <span style="color:#FFFFFF;font-size: 16px;">新增专项会议信息</span>
      </template>
      <el-form :model="addMultiForm"
               label-width="85px"
               :rules="addMultiFormRules"
               ref="addMultiForm">
        <el-form-item label="工作项目:"
                      prop="WorkItem">
          <el-input v-model="addMultiForm.WorkItem"
                    maxlength="50"
                    show-word-limit
                    auto-complete="off"
                    placeholder="请填写项目名称"></el-input>
        </el-form-item>
        <el-form-item label="工作描述:"
                      prop="WorkDescription">
          <span :style="{'font-size':'9px','margin-right':'3px','font-weight':descModeVal?'normal':'bold'}">简单文本</span>
           <el-switch style="margin-left:0px;z-index:99;"
              v-model="descModeVal" @change="handleChangeDescMode"
              inactive-color="#13ce66"
              active-color="#ff4949"
              >
            </el-switch>
            <span :style="{'font-size':'9px','margin-left':'3px','font-weight':!descModeVal?'normal':'bold'}">富文本</span>
          <editordesc v-show="descModeVal" ref="editorDescElem" style="width: 100%" :catchData="catchDataDesc" />
          <el-input v-show="!descModeVal" @change="handleChangeWorkdesc" v-model="simpleWorkDescription"
                    type="textarea"
                    maxlength="500"
                    placeholder="请描述工作内容"
                    :autosize="{ minRows: 4, maxRows: 7}"
                    show-word-limit></el-input>
        </el-form-item>
        <el-row>
          <el-col :span="12">
            <el-form-item label="计划完成时间:" label-width="120"
                          prop="PlanComplateTime">
              <el-date-picker v-model="addMultiForm.PlanComplateTime"
                              :picker-options="pickerOptions0"
                              type="date"
                              value-format="yyyy-MM-dd"
                              placeholder="请选择日期"
                              style="left: 10px;"></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-row>
              <el-col :span="0.1"
                      style="margin-top:10px; margin-left: -1%;">
                <span style="color: red;">*</span>
              </el-col>
              <el-col :span="20">
                <el-form-item label="责任人:"
                              prop="PersonOfDuty" style="white-space:nowrap;">
                  <el-input v-model="addMultiForm.PersonOfDuty"
                            placeholder="请选择责任人"
                            readonly>{{dutyusername}}</el-input>
                  <el-button type="text"
                             size="mini"
                             @click="handleChooseDutyUser">选择人员</el-button>
                </el-form-item>
              </el-col>
              <!-- <el-col :span="2">
                                    <el-button type="text">
                                        <userchoosetool @dutycallFunction="dutycallFunction" :callbackmsg = "dutypeo"></userchoosetool>
                                    </el-button>
                                </el-col> -->
            </el-row>
          </el-col>
        </el-row>
        <!-- <el-form-item label="年度计划:" prop="YearPlanTask">
                        <el-select v-model = "addMultiForm.YearPlanTask" @change="YearPlanchange">
                            <el-option v-for = "item in YearPlanTaskList" :key = "item.Id" :value = "`${item.YearPlanId}|${item.TaskId}|${item.WorkItem}`" :label = "item.WorkItem">
                            </el-option>
                        </el-select>
                    </el-form-item> -->
        <el-form-item label="备注:"
                      prop="Remark">
          <el-input v-model="addMultiForm.Remark"
                    type="textarea"
                    maxlength="100"
                    show-word-limit></el-input>
        </el-form-item>
        <el-form-item label="附件:"
                      prop="FileAddress">
          <upload-files :files="fileList"
                        :key="key"
                        action="https://api.gradgroup.cn/ftp/ftp/upload"
                        :limit="5"
                        @fun="dealFiles"
                        @delfun="deleteFiles"
                        :multiple="false"
                        :IsDisabled="operation1"
                        :IsDel="operation1"></upload-files>
        </el-form-item>
      </el-form>
      <div slot="footer"
           class="dialog-footer">
        <el-button type="goon"
                   @click.native="addMultiSubmit">完成</el-button>
      </div>
    </el-dialog>
    <!--指派界面-->
    <el-dialog :visible.sync="addAssignFormVisible"
               v-model="addAssignFormVisible"
               :close-on-press-escape="false"
               :close-on-click-modal="false"
               @close="closeassign"
               @opened="assignTaskOpened"
               @closed="assignTaskClosed"
               center>
      <template slot="title">
        <span style="color:#FFFFFF;font-size: 16px;">指派专项会议</span>
      </template>
      <el-form label-width="85px"
               style="white-space:nowrap;">
        <el-row>
          <el-col :span="0.1"
                  style="margin-top:10px">
            <span style="color: red;">*</span>
          </el-col>
          <el-col :span="8">
            <el-form-item label="被指派人:"
                          prop="AssignName">
              <el-input v-model="addForm.AssignName"
                        readonly
                        placeholder="请选择被指派人">{{needName}}</el-input>
            </el-form-item>
          </el-col>
          <el-col :span="2">
            <el-button type="text">
              <userchoosetool @assigncallFunction="assigncallFunction"
                              :callbackmsg="assignpeo"></userchoosetool>
            </el-button>
          </el-col>
        </el-row>
        <!-- <el-row>
                    <el-col :span="0.1" style="margin-top:10px">
                        <span style="color: red;">*</span>
                    </el-col>
                    <el-col :span="20">
                        <el-form-item label="完成后需我审核:" prop="IsNeedApproval">
                            <el-switch v-model="addForm.IsNeedApproval" style="left: 30px;"></el-switch>
                        </el-form-item>
                    </el-col>
                </el-row> -->
        <el-row>
          <el-col :span="0.1"
                  style="margin-top:10px">
            <span style="color: red;">*</span>
          </el-col>
          <el-col :span="23">
            <el-form-item label="工作项目:"
                          prop="WorkItem2">
              <el-input v-model="WorkItem2"
                        maxlength="30"
                        show-word-limit></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="0.1"
                  style="margin-top:10px">
            <span style="color: red;">*</span>
          </el-col>
          <el-col :span="23">
            <el-form-item label="工作描述:"
                          prop="WorkDescription2">
              <editordesc v-show="assignDescModeVal" ref="editorAssignDescElem" style="width: 100%" :catchData="catchAssignDataDesc" />
              <el-input v-show="!assignDescModeVal" v-model="WorkDescription2"
                        type="textarea"
                        :autosize="{ minRows: 4, maxRows: 7}"
                        maxlength="500"
                        show-word-limit></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row style="height: 40px;">
          <el-col :span="0.1"
                  style="margin-top:5px">
            <span style="color: red;">*</span>
          </el-col>
          <el-col :span="2.5" style="margin-top:5px">
            <span>计划完成时间:</span>
          </el-col>
          <el-col :span="7">
            <el-date-picker :picker-options="pickerOptions0"
                            v-model="PlanComplateTime2"
                            type="date"
                            value-format="yyyy-MM-dd"
                            placeholder="请选择日期"></el-date-picker>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer"
           class="dialog-footer">
        <el-button type="goon"
                   @click.native="addSubmit">提交</el-button>
      </div>
    </el-dialog>
    <!--编辑任务界面-->
    <el-dialog :visible.sync="editFormVisible"
               v-model="editFormVisible"
               :close-on-press-escape="false"
               :close-on-click-modal="false"
               @opened="editTaskOpened"
               @closed="editTaskClosed"
               center>
      <template slot="title">
        <span style="color:#FFFFFF;font-size: 16px;">编辑专项会议</span>
      </template>
      <el-form :model="editForm"
               label-width="85px"
               :rules="editFormRules"
               ref="editForm">
        <el-form-item label="会议名称:"
                      prop="MeetingName">
          <el-input v-model="editForm.MeetingName"
                    disabled></el-input>
        </el-form-item>
        <el-form-item label="工作项目:"
                      prop="WorkItem">
          <el-input v-model="editForm.WorkItem"
                    maxlength="30"
                    show-word-limit></el-input>
        </el-form-item>
        <el-form-item label="工作描述:"
                      prop="WorkDescription">
          <editordesc v-show="editDescModeVal" ref="editorEditDescElem" style="width: 100%" :catchData="catchEditDataDesc" />
          <el-input v-show="!editDescModeVal" v-model="editForm.WorkDescription"
                    type="textarea"
                    :autosize="{ minRows: 4, maxRows: 7}"
                    maxlength="500"
                    show-word-limit></el-input>
        </el-form-item>
        <el-row style="height: 40px; margin-bottom: 20px;">
          <el-col :span="0.1">
            <span style="color: red;">*</span>
          </el-col>
          <el-col :span="2.5">
            <span>计划完成时间:</span>
          </el-col>
          <el-col :span="7">
            <el-date-picker :picker-options="pickerOptions0"
                            v-model="editForm.PlanComplateTime"
                            type="date"
                            value-format="yyyy-MM-dd"
                            placeholder="请选择日期"></el-date-picker>
          </el-col>
        </el-row>
        <el-form-item label="备注:"
                      prop="Remark">
          <el-input v-model="editForm.Remark"
                    maxlength="50"
                    show-word-limit></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer"
           class="dialog-footer">
        <el-button type="goon"
                   @click="editSubmit">提交</el-button>
      </div>
    </el-dialog>

    <taskinfo v-if="checkVisible" :data="checkForm" @closeFunction="checkclose" headerText="查看专项会议信息" type="zhuanxiang" ></taskinfo>

    <!--新增工作进度界面-->
    <el-dialog title="新增工作进度"
               :visible.sync="addProgressVisible"
               v-model="addProgressVisible"
               :close-on-press-escape="false"
               :close-on-click-modal="false"
               @close="proclose"
               @opened="addopen"
               style="width: 80%; left: 15%;"
               center>
      <template slot="title">
        <span style="color:#FFFFFF;font-size: 16px;">新增工作进度</span>
      </template>
      <el-form :model="addProgressForm"
               :rules="addProgressFormRules">
        <el-form-item label="进度描述:"
                      prop="ProgressDescription">
          <el-input v-model="addProgressForm.ProgressDescription"
                    rows="5"
                    maxlength="700"
                    placeholder="请尽可能的描述任务推进过程、量化数据和成果"
                    show-word-limit
                    type="textarea"></el-input>
        </el-form-item>
        <el-form-item label="具体进度:">
          <el-row>
            <el-col :span="11">
              <el-slider v-model="pValue2"
                         @input="handleProcessInput"
                         :step="5"></el-slider>
            </el-col>
            <el-col :span="2.2">
              <div style="margin-left:9px">{{pValue2}}%</div>
            </el-col>
            <el-col :span="7">
              <div style="margin-left:3px;font-size:12px">
                <i class="el-icon-info"></i>拖动滑块设置进度百分比
              </div>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item label="附件:">
          <upload-files :files="fileList"
                        :key="key"
                        action="https://api.gradgroup.cn/ftp/ftp/upload"
                        :limit="5"
                        @fun="dealFiles2"
                        @delfun="deleteFiles2"
                        :multiple="false"
                        :IsDisabled="operation1"
                        :IsDel="operation1"></upload-files>
        </el-form-item>
      </el-form>
      <div slot="footer"
           class="dialog-footer">
        <el-button type="goon"
                   @click.native="checkSubmit">保存</el-button>
      </div>
    </el-dialog>

    <el-dialog title="选择人员"
               :visible.sync="addUserVisible"
               v-model="addUserVisible"
               width="75%"
               center
               append-to-body>
      <UserChoose :data="choosedusers"
                  :all="true"
                  :single="false"
                  @callback="chooseUserCallBack"></UserChoose>
    </el-dialog>

    <el-dialog title="选择人员"
               :visible.sync="addDutyUserVisible"
               v-model="addDutyUserVisible"
               width="75%"
               center
               append-to-body>
      <UserChoose :data="chooseddutyusers"
                  :all="true"
                  :single="false"
                  @callback="chooseDutyUserCallBack"></UserChoose>
    </el-dialog>

  </section>
</template>
<script>
import util from '../../../util/date';
import { Loading } from 'element-ui'
import { AddTaskCollect, RemoveTaskCollect, AddTaskProgressReview,QueryReviewByProgressId, QueryPageUniquelyProjectMeetingTasksByUserCode20210122, QueryChildTasksByParentId, GetUserPostList, AddUniquelyProjectMeeting, AssignTask, CancelTask, QueryTaskProgressByTaskId, AddTaskProgress, DeleteTaskProgress, UpdateTaskContent, ReSubmitApproval, GetYearPlanListByUserCode } from '../../api/oa';
import { AddUserRole, GetRoleUserList } from '../../api/hr'
import taskinfo from "../../components/taskinfo.vue"
import Userchoosetool from "../../components/Userchoosetool";
import { getButtonList } from "../../promissionRouter";
import Toolbar from "../../components/Toolbar";
import UploadFiles from "../../components/UploadFiles";
import UserChoose from "../../components/UserChoose";
import FileSaver from "file-saver";
import XLSX from "xlsx";
import editordesc from '../../components/editordesc.vue'
export default {
  components: { taskinfo, editordesc, Toolbar, Userchoosetool, UploadFiles, UserChoose },
  data () {
    return {
      assignDescModeVal:false,
      editDescModeVal:false,
      descModeVal: false,
      simpleWorkDescription:null,
      currentActiveId: null,
      loading: '',
      currentPage: 1, // 当前页码
      total: null, // 总条数
      totaldata: null,
      pageSize: 10, // 每页的数据条数
      page: 1,
      localuser: null,
      currentRow: null,
      maps: new Map(),
      progresscheck: null,      //进行完成进度验证
      currentProgressRowIndex: null,
      proRow: null,
      //指令人
      orderneedname: null,
      orderpeo: 'orderpeo',
      meetings: [],
      buttonList: [],
      historyList: [], //历史搜索记录，存本地
      tasks: [],
      content:'',
      mapReview:new Map(),
      dutyusername: '',
      ccusername: '',
      userid: [], //暂存选中角色下所有用户的id
      choosedusers: [],//抄送人
      chooseddutyusers: [],//责任人
      addUserVisible: false,
      addDutyUserVisible: false,
      showCreated: true,
      showAssign: false,
      showhistory: false,
      showminus: false,
      showplus: true,
      singleAss: false,     //是否单独指派
      checkVisible: false,
      editFormVisible: false,
      addProgressVisible: false,
      istaskpro: true,
      filters: {
        name: ''
      },
      buttonListmsg: 'taskmanage',
      pValue1: null,   //进度条数值
      pValue2: null,   //进度条数值
      customColor: '#6f7ad3',   //进度条颜色
      needName: null,
      WorkItem2: null,
      WorkDescription2: null,
      PlanComplateTime2: null,
      assignpeo: 'assignpeo',
      dutypeo: 'dutypeo',
      pickerOptions0: {
        disabledDate (time) {
          return time.getTime() < Date.now() - 8.64e7;
        }
      },
      addFormVisible: false,
      addAssignFormVisible: false,
      fileList: [],
      operation1: true,
      key: 0,
      addFormRules: {
        MeetingName: [
          { required: true, message: '请填写专项会议名称', trigger: 'change' }
        ],
      },
      addMultiFormRules: {
        WorkItem: [
          { required: true, message: '请填写工作项目名称', trigger: 'blur' }
        ],
        WorkDescription: [
          { required: true, message: '请填写具体工作描述', trigger: 'blur' }
        ],
        PlanComplateTime: [
          { required: true, message: '请选择计划完成时间', trigger: 'blur' }
        ]
      },
      addForm: {
        MeetingName: null,
        //指令人
        InstructUserName: null,
        InstructUserCode: null,

      },
      addMultiForm: {
        WorkItem: null,
        WorkDescription: null,
        PersonOfDuty: null,
        YearPlanTask: null,
        Tag: null,
        TagName: null,
        Remark: null,
        FileName: null,
        FileAddress: null
      },
      checkForm: {            //查看表单初始化
        Id: null,
        MeetingName: null,
        WorkItem: null,
        WorkDescription: null,
        PlanComplateTime: null,
        Remark: null,
        IsNeedApproval: null
      },
      addProgressForm: {            //进度新增表单初始化
        ProgressDescription: null,
        CreatedBy: null,
        CreatedId: null
      },
      addProgressFormRules: {
        ProgressDescription: [
          { required: true, message: '请输入进度描述', trigger: 'blur' }
        ]
      },
      editForm: {              //编辑表单初始化
        Id: null,
        WorkItem: null,
        WorkDescription: null,
        PlanComplateTime: null,
        Remark: null,
      },
      editFormRules: {
        WorkItem: [
          { required: true, message: '请填写工作项目名称', trigger: 'blur' }
        ],
        WorkDescription: [
          { required: true, message: '请填写具体工作描述', trigger: 'blur' }
        ]
      },
      YearPlanTaskList: [],          //年度计划任务类型
      addMultiFormVisible: false,
      meetingList: [],
      multitype: null,
      multiindex: null,
      collectBtn:false,
      collectRowId:''
    }
  },
  watch: {
    contentDesc(){

    },
    contentEditDesc(){

    },
    contentAssignDesc(){

    },
  },
  methods: {
    catchDataDesc(c){
      this.contentDesc = c
      this.addMultiForm.WorkDescription = c;
    },
    catchEditDataDesc(c){
      this.contentEditDesc = c
      this.editForm.WorkDescription = c;
    },
    catchAssignDataDesc(c){
      this.contentAssignDesc = c
      // this.addForm.WorkDescription2 = c;
      this.WorkDescription2 = c;
    },
    editTaskOpened(){
      if(this.editForm.WorkDescription.indexOf('<p')!=-1){
        this.editDescModeVal = true;
        this.$refs.editorEditDescElem.append(this.editForm.WorkDescription);
        // this.editForm.WorkDescription = this.editForm.WorkDescription;
      }else{
        this.editDescModeVal = false;
      }
    },
    editTaskClosed(){
        this.$refs.editorEditDescElem.clean('');
    },
    assignTaskOpened(){
      if(this.WorkDescription2.indexOf('<p')!=-1){
        this.assignDescModeVal = true;
        this.$refs.editorAssignDescElem.append(this.WorkDescription2);
        // this.addForm.WorkDescription2 = this.addForm.WorkDescription2;
      }else{
        this.assignDescModeVal = false;
      }
    },
    assignTaskClosed(){
        this.$refs.editorAssignDescElem.clean('');
    },
    handleChangeWorkdesc(){
      let self = this;
      self.addMultiForm.WorkDescription = self.simpleWorkDescription;
    },
    handleChangeDescMode(){
      let self = this;
      if(self.descModeVal){
        self.addMultiForm.WorkDescription = self.contentDesc;
      }else{
        self.addMultiForm.WorkDescription = self.simpleWorkDescription;
      }
    },
    handleCollect(row)
    {
      if(row.CollectId==null)
      {
        var para={};
        para.TaskID=row.Id;
        var user = JSON.parse(window.localStorage.user);
        para.CreatedId = user.sub;
        para.CreatedBy = user.name;
        // console.log(para)
        // return;
        AddTaskCollect(para).then((res) => {
          this.$message({
            message: '已特别关注',
            type: 'success'
          });
          // console.log(res);
          var id=res.data.response;
          row.CollectId=id;
        })
      }else{
        this.$confirm("确定要关闭对此任务的关注吗？", "提示", {}).then(() => {
          var para={};
          para.Id=row.CollectId;
          para.TaskID=row.Id;
          RemoveTaskCollect(para).then((res) => {
            this.$message({
              message: '已取消关注',
              type: 'success'
            });
            row.CollectId=null;
          })
        }).catch(()=>{});
      }
    },
    tabmouseEnter(row, column, cell, event)
    {
      if(column.property!='WorkItem')return;
      this.collectBtn=true;
      this.collectRowId=row.Id;
      // console.log(row)
    },
    tabmouseLeave(row, column, cell, event)
    {
      this.collectBtn=false;
      this.collectRowId='';
    },
    handleChange(val)
    {
      // console.log(val)
      if(val.length>0)
      {
        var progressId = val[0];
        this.loadReview(progressId);
      }
    },
    catchData(c)
    {
      // console.log(c)
      this.content=c;
    },
    handlePopoShow()
    {
      
    },
    handlePopoReplyShow()
    {

    },
    myname()
    {
      var user = JSON.parse(window.localStorage.user);
      return user.name;
    },
    handleReplyReview(progress,rid)
    {
      // console.log(progress);
      // console.log(rid);
      let self=this;
      var progressId = progress.Id;
      var user = JSON.parse(window.localStorage.user);
      var para={};
      para.TaskID=this.checkForm.Id;
      para.ProgressID=progressId;
      para.ReplayID = rid;
      para.ReviewContent = self.content;
      para.CreatedId = user.sub;
      para.CreatedBy = user.name;
      // console.log(para);

      AddTaskProgressReview(para).then((res) => {
        // console.log(res);
        if (res.data.success) {
          this.$message({
            message: res.data.msg,
            type: 'success'
          });
          for(var a=0;a<self.tasks.length;a++)
          {
            if(this.tasks[a].Id==progressId)
            {
              this.tasks[a].ReviewCount=this.tasks[a].ReviewCount+1;
              break;
            }
          }
          this.loadReview(progressId);
        } else {
          this.$message({
            message: res.data.msg,
            type: 'error'
          });
        }
      })
    },
    handleSendReview(progress)
    {
      let self=this;
      // this.$confirm('评论即将发表，确定吗？', '提示', {}).then(() => 
      {
        var progressId = progress.Id;
        var user = JSON.parse(window.localStorage.user);
        var para={};
        para.TaskID=this.checkForm.Id;
        para.ProgressID=progressId;
        para.ReplayID = null;
        para.ReviewContent = self.content;
        para.CreatedId = user.sub;
        para.CreatedBy = user.name;
        // console.log(para);

        AddTaskProgressReview(para).then((res) => {
          // console.log(res);
          if (res.data.success) {
            this.$message({
              message: res.data.msg,
              type: 'success'
            });
            for(var a=0;a<self.tasks.length;a++)
            {
              if(this.tasks[a].Id==progressId)
              {
                this.tasks[a].ReviewCount=this.tasks[a].ReviewCount+1;
                break;
              }
            }
            this.loadReview(progressId);
          } else {
            this.$message({
              message: res.data.msg,
              type: 'error'
            });
          }
        })
      }
      // )
    },
    loadReview(progressid)
    {
      let self=this;
      // console.log(progressid)
      let para = { progressId: progressid };
      QueryReviewByProgressId(para).then((res) => {
        // console.log(res)
        var data = res.data.response;
        // console.log(data);
        self.mapReview.set(progressid,data);
        // console.log(self.mapReview);
        this.$forceUpdate()
        // for(var a=0;a<data.length;a++)
        // {
        //   self.mapReview.set(progressid,data);
        // }
      });
    },
    getReplyReview(arr,id)
    {
      // console.log(arr);
      // console.log(id);
      var o ={
        CreatedBy:'',
        CreatedId:'',
        CreatedTime:'',
        ReviewContent:''
      };
      for(var i=0;i<arr.length;i++)
      {
        if(arr[i].Id==id)
        {
          o = arr[i];
          break;
        }
      }
      return o;
    },
    handleExport()
    {
        this.exportExcel();
    },
    //定义导出Excel表格事件
    exportExcel() {
        /* 从表生成工作簿对象 */
        var wb = XLSX.utils.table_to_book(document.querySelector("#mytable"));
        /* 获取二进制字符串作为输出 */
        var wbout = XLSX.write(wb, {
            bookType: "xlsx",
            bookSST: true,
            type: "array"
        });
        //console.log(this.DepartUserRow);
        var filename=util.formatDate.format(new Date(),'yyyy-MM-dd hh:mm');
        try {
            FileSaver.saveAs(
            //Blob 对象表示一个不可变、原始数据的类文件对象。
            //Blob 表示的不一定是JavaScript原生格式的数据。
            //File 接口基于Blob，继承了 blob 的功能并将其扩展使其支持用户系统上的文件。
            //返回一个新创建的 Blob 对象，其内容由参数中给定的数组串联组成。
            new Blob([wbout], { type: "application/octet-stream" }),
            //设置导出文件名称
            filename+"专项会议任务导出列表.xlsx"
            );
        } catch (e) {
            if (typeof console !== "undefined") console.log(e, wbout);
        }
        return wbout;
    },
    handleProcessSelectChange (taskid, index) {
      this.currentActiveId = taskid;
      this.currentProgressRowIndex = index;
    },
    showTaskFile (file) {
      window.open(file, "_blank");
    },
    handleProcessInput (val) {
      if (this.pValue2 < this.pValue1) {
        this.pValue2 = this.pValue1;
      }
      if (this.progresscheck == true) {
        if (this.pValue2 >= 100) {
          if (this.checkForm.IsNeedApproval == false) {
            this.$message({
              message: '任务已完成，请提交！',
              type: 'success'
            });
          } else {
            this.$message({
              message: '任务已完成，请提交审核！',
              type: 'warning'
            });
          }
          this.pValue2 = 100;
        }
      } else {
        if (this.pValue2 > 95) {
          this.$message({
            message: "当前任务被指派人并未都完成被指派任务,故进度最大只能添加至95%！",
            type: "warning",
            duration: 10000
          });
          this.pValue2 = 95;
        }
      }
    },
    handleChooseDutyUser () {
      this.addDutyUserVisible = true;
    },
    handleChooseCCUser () {
      this.addUserVisible = true;
    },
    //监听子组件事件 选择人员回调
    chooseDutyUserCallBack (data) {
      // console.log(data);
      var newdata = data.map(function (item) {
        return item["Id"];
      });
      var namenewdata = data.map(function (item) {
        return item["UserNameClaim"];
      });
      // console.log(newdata);

      var str = "";
      for (var i = 0; i < namenewdata.length; i++) {
        str += namenewdata[i] + ",";
      }
      if (str.length > 0) {
        str = str.substr(0, str.length - 1);
      }

      var idstr = "";
      for (var i = 0; i < newdata.length; i++) {
        idstr += newdata[i] + ",";
      }
      if (idstr.length > 0) {
        idstr = idstr.substr(0, idstr.length - 1);
      }

      this.dutyusername = str;
      this.addMultiForm.PersonOfDutyCode = idstr;
      this.addMultiForm.PersonOfDuty = str;
      this.addDutyUserVisible = false;
    },
    //监听子组件事件 选择人员回调
    chooseUserCallBack (data) {
      // console.log(data);
      var newdata = data.map(function (item) {
        return item["Id"];
      });
      var namenewdata = data.map(function (item) {
        return item["UserNameClaim"];
      });
      // console.log(newdata);

      var str = "";
      for (var i = 0; i < namenewdata.length; i++) {
        str += namenewdata[i] + ",";
      }
      if (str.length > 0) {
        str = str.substr(0, str.length - 1);
      }

      var idstr = "";
      for (var i = 0; i < newdata.length; i++) {
        idstr += newdata[i] + ",";
      }
      if (idstr.length > 0) {
        idstr = idstr.substr(0, idstr.length - 1);
      }
      this.ccusername = str;
      this.addForm.CCUserCode = idstr;
      this.addForm.CCUserName = str;
      this.addUserVisible = false;
    },
    dealFiles (data) {
      // console.log(data.backData);
      if (data.backData.length > 0) {
        this.addMultiForm.FileArry = data.backData;
        var filename = "";
        var fileaddress = "";
        data.backData.forEach(element => {
          filename += element.FileName;
          filename += ",";
          fileaddress += element.URL;
          fileaddress += ",";
        });
        this.addMultiForm.FileName = filename;
        this.addMultiForm.FileAddress = fileaddress;
      }
    },
    dealFiles2 (data) {
      // console.log(data.backData);
      if (data.backData.length > 0) {
        // this.addProgressForm.FileArry = data.backData;
        // this.addProgressForm.FileName = data.backData[0].FileName;
        // this.addProgressForm.FileAddress = data.backData[0].URL;
        this.addProgressForm.FileArry = data.backData;
        var filename = "";
        var fileaddress = "";
        data.backData.forEach(element => {
          filename += element.FileName;
          filename += ',';
          fileaddress += element.URL;
          fileaddress += ',';
        });
        this.addProgressForm.FileName = filename;
        this.addProgressForm.FileAddress = fileaddress;
      }
    },
    deleteFiles (data) {
      if (data.backData.length >= 0) {
        this.addMultiForm.FileArry = "";
        var filename = "";
        var fileaddress = "";
        data.backData.forEach(element => {
          filename += element.FileName;
          filename += ",";
          fileaddress += element.URL;
          fileaddress += ",";
        });
        this.addMultiForm.FileName = filename;
        this.addMultiForm.FileAddress = fileaddress;
      }
    },
    deleteFiles2 (data) {
      // if(data.backData.length==0)
      // {
      //     this.addProgressForm.FileArry = "";
      //     this.addProgressForm.FileName = ""
      //     this.addProgressForm.FileAddress = "";
      // }
      if (data.backData.length >= 0) {
        this.addProgressForm.FileArry = "";
        var filename = "";
        var fileaddress = "";
        data.backData.forEach(element => {
          filename += element.FileName;
          filename += ",";
          fileaddress += element.URL;
          fileaddress += ",";
        });
        this.addProgressForm.FileName = filename;
        this.addProgressForm.FileAddress = fileaddress;
      }
    },
    callFunction (item) {
      this.filters = {
        name: item.search
      };
      this[item.Func].apply(this, item);

    },
    load (tree, treeNode, resolve) {
      // 将当前选中节点数据存储到map中
      this.maps.set(tree.Id, { tree, treeNode, resolve })
      let para = {
        taskId: tree.TaskId,
      };
      if (tree.PersonOfDuty == '') {
        para.notaskType = 2;
      }
      else {
        para.notaskType = 0;
      }
      var user = JSON.parse(window.localStorage.user);
      var userCode = user.sub;
      QueryChildTasksByParentId(para).then((res) => {
        var data = [];
        var d = res.data.response;
        d.forEach(element => {
          element.MeetingName = tree.MeetingName;
          if (tree.PersonOfDuty == '') {
            if (element.CreatedId == userCode || element.PersonOfDutyCode == userCode) {
              data.push(element);
            }
          } else {
            data.push(element);
          }
          // data.push(element);
        });
        resolve(data);
      });

    },
    // 重新触发树形表格的loadTree函数
    refreshLoadTree (parentId) {
      // 根据父级id取出对应节点数据
      const { tree, treeNode, resolve } = this.maps.get(parentId)
      this.$set(this.$refs.multipleTable.store.states.lazyTreeNodeMap, parentId, [])
      if (tree) {
        this.load(tree, treeNode, resolve)
      }
    },
    selectCurrentRow (val) {
      this.currentRow = val;
    },
    taskProgressRowClassName ({ row, rowIndex }) {
      row.index = rowIndex;
    },
    onRowClick (row, event, column) {
      //行点击消除new标记
      this.currentProgressRowIndex = row.index;
    },
    selectProRow (val) {
      this.proRow = val;
    },
    formatPlanComplateTime: function (row, colume) {
      return (!row.PlanComplateTime || row.PlanComplateTime == '') ? '' : util.formatDate.format(new Date(row.PlanComplateTime), 'yyyy-MM-dd');
    },
    formatProgress: function (row, col) {
      if (row.TaskProgressValue >= 0) {
        return row.TaskProgressValue + "%";
      }
    },
    formatCommonTaskClassifyText: function (row, colume) {
      if (row.TaskClassify == 1) {
        return row.CommonTaskClassifyText;
      } else {
        if (row.TaskClassify == 2) {
          return "跨部门协调";
        }
        else if (row.TaskClassify == 3) {
          return "例会项目";
        } else if (row.TaskClassify == 4) {
          return "年度计划";
        } else if (row.TaskClassify == 5) {
          return "专项会议";
        }
        else {
          return "其他";
        }
      }
    },
    formatCreateTime: function (row, column) {
      return (!row.CreateTime || row.CreateTime == '') ? '' : util.formatDate.dateformat(new Date(row.CreateTime));
    },
    formatTipCreateTime: function (row, column) {
      return (!row.CreateTime || row.CreateTime == '') ? '' : util.formatDate.format(new Date(row.CreateTime), 'yyyy-MM-dd hh:mm');
    },
    formatCreatedOn: function (row, colume) {
      return (!row.CreatedOn || row.CreatedOn == '') ? '' : util.formatDate.format(new Date(row.CreatedOn), 'yyyy-MM-dd hh:mm');
    },
    tableRowClassName ({ row, rowIndex }) {
      var user = JSON.parse(window.localStorage.user);
      if (row.PersonOfDuty != user.name) {
        return 'success-row';
      } else {
        if (rowIndex % 2 !== 0) {
          return 'warning-row';
        }
        return '';
      }
    },
    handleCurrentChange (val) {
      this.page = val;
      this.getProfessionalProjectMeetings();
      this.page = 1;
    },
    getProfessionalProjectMeetings () {
      var user = JSON.parse(window.localStorage.user);
      let para = {
        userCode: user.sub,
        pageIndex: this.page,
        pageSize: this.pageSize,
        queryKey: this.filters.name
      };
      QueryPageUniquelyProjectMeetingTasksByUserCode20210122(para).then((res) => {
        this.total = res.data.response.pageCount;
        this.totaldata = res.data.response.dataCount;
        this.meetings = res.data.response.data;
      })
    },
    //新增
    handleAdd () {
      this.addFormVisible = true;
      this.addForm = {
        MeetingName: null,
        WorkItem: null,
        WorkDescription: null,
        PersonOfDuty: null,
        //指令人
        InstructUserName: null,
        InstructUserCode: null,
      }
      this.showhistory = false;
    },
    YearPlanchange (val) {
      this.addMultiForm.Tag = val.split('|')[1];
      this.addMultiForm.TagName = val.split('|')[2];
    },
    //新增提交
    addSubmit () {
      let para = Object.assign({}, this.addForm);
      var user = JSON.parse(window.localStorage.user);
      para.CreatedId = user.sub;
      para.CreatedBy = user.name;
      if (this.singleAss == false) {
        if (this.addForm.MeetingName == null || this.addForm.InstructUserName == null) {
          this.tipMessage();
          return;
        }
        this.Loadingstart();
        this.meetingList.forEach(item => {
          this.$set(item, 'MeetingName', para.MeetingName);
          this.$set(item, 'InstructUserName', para.InstructUserName);
          this.$set(item, 'InstructUserCode', para.InstructUserCode);
          this.$set(item, 'CCUserName', para.CCUserName);
          this.$set(item, 'CCUserCode', para.CCUserCode);
          this.$set(item, 'CreatedBy', para.CreatedBy);
          this.$set(item, 'CreatedId', para.CreatedId);
        });
        // console.log(this.meetingList);
        // alert('submit');
        // return;
        AddUniquelyProjectMeeting(this.meetingList).then((res) => {
          if (res.data.success) {
            this.$message({
              message: res.data.msg,
              type: "success"
            });
            this.Loadignend();
            this.$refs["addForm"].resetFields();
            this.meetingList = [];
            this.addFormVisible = false;
            this.setlocalHistory(para.MeetingName); // 将搜索值加入本地localStorage
            this.historyList = localStorage.getItem('localHistory').split('|'); // 从本地localStorage取出搜索历史并展示
            this.meetings = [];
            this.getProfessionalProjectMeetings();
          } else {
            this.$message({
              message: res.data.msg,
              type: "error"
            });
          }
        });
      } else {
        if (this.addForm.AssignName == null || this.WorkItem2 == null || this.WorkDescription2 == null || this.PlanComplateTime2 == null) {
          this.tipMessage();
          return;
        }
        this.Loadingstart();
        let para = Object.assign({}, this.addForm);
        para.WorkItem2 = this.WorkItem2;
        para.WorkDescription2 = this.WorkDescription2;
        para.PlanComplateTime2 = this.PlanComplateTime2;
        para.Id = this.currentRow.Id;
        para.CommonTaskClassify = 0;
        AssignTask(para).then((res) => {
          if (res.data.success) {
            this.$message({
              message: res.data.msg,
              type: 'success'
            });
            this.Loadignend();
            this.addAssignFormVisible = false;
            this.needName = null;
            this.singleAss = false;
            this.$refs.multipleTable.toggleRowExpansion(this.currentRow, false);
            // window.location.reload();
            this.meetings = [];
            this.getProfessionalProjectMeetings();
            // this.refreshLoadTree(this.currentRow.Id);
          } else {
            this.$message({
              message: res.data.msg,
              type: 'error'
            });
          }
        });
      }
    },
    //退回后再次提交
    readdSubmit (row) {
      this.$confirm('请尽量完善任务描述后再次提交,确定要提交吗？', '提示', {}).then(() => {
        let para = { taskId: row.Id, taskClassify: row.TaskClassify, taskParentId: row.ParentId };
        ReSubmitApproval(para).then((res) => {
          if (res.data.success) {
            this.$message({
              message: res.data.msg,
              type: 'success'
            });
            this.getProfessionalProjectMeetings();
          } else {
            this.$message({
              message: res.data.msg,
              type: 'error'
            });
          }
        });
      });
    },
    //复制会议
    copyAddNew () {
      let row = this.currentRow;
      if (!row) {
        this.$message({
          message: "请选择要复制的一行数据！",
          type: "error",
          duration: 10000
        });
        return;
      }
      this.addFormVisible = true;
      this.addForm = Object.assign({}, row);

    },

    //编辑专项会议项目
    handleEdit () {
      let row = this.currentRow;
      if (!row) {
        this.$message({
          message: "请选择要编辑的一行数据！",
          type: "warning",
          duration: 10000
        });
        return;
      } 
      if(row.NoTask>0)
      {
        this.$message({
          message: "请展开会议分组后选中任务数据进行操作",
          type: "warning",
        });
        return;
      }
      if (row.TaskProgressValue != 0) {
        this.$message({
          message: '当前会议项目已产生进度，不能编辑',
          type: 'warning',
          duration: 10000
        });
      } else if (row.ParentId != null) {
        this.$message({
          message: '当前会议项目为子任务，不能编辑',
          type: 'warning',
          duration: 10000
        });
      }
      else {
        this.editFormVisible = true;
        this.editForm = Object.assign({}, row);
      }
    },
    //编辑提交
    editSubmit () {
      this.$refs.editForm.validate((valid) => {
        if (valid) {
          this.Loadingstart();
          let para = Object.assign({}, this.editForm);
          UpdateTaskContent(para).then((res) => {
            if (res.data.success) {
              this.$message({
                message: res.data.msg,
                type: 'success'
              });
              this.Loadignend();
              this.$refs['editForm'].resetFields();
              this.currentRow = null;
              this.editFormVisible = false;
              this.meetings = [];
              this.getProfessionalProjectMeetings();
            } else {
              this.$message({
                message: res.data.msg,
                type: 'error'
              });
            }
          });
        }
      });
    },
    //指派
    handleAssign () {
      let row = this.currentRow;
      var user = JSON.parse(window.localStorage.user);
      if (!row) {
        this.$message({
          message: "请选择要指派的一行数据！",
          type: "warning",
          duration: 10000
        });
        return;
      } else {

        if(row.NoTask>0)
        {
          this.$message({
            message: "请展开会议分组后选中任务数据进行操作",
            type: "warning",
          });
          return;
        }
        if (row.PersonOfDuty != user.name) {
          this.$message({
            message: '非当前专项会议责任人，不能指派',
            type: 'error',
            duration: 10000
          });
        } else {
          this.WorkItem2 = row.WorkItem;
          this.WorkDescription2 = row.WorkDescription;
          this.addAssignFormVisible = true;
          this.singleAss = true;
        }
      }
    },
    //取消任务
    handleCancel () {
      let row = this.currentRow;
      if (!row) {
        this.$message({
          message: "请选择要取消的一行数据！",
          type: "warning",
          duration: 10000
        });
        return;
      }
      if(row.NoTask>0)
      {
        this.$message({
          message: "请展开会议分组后选中任务数据进行操作",
          type: "warning",
        });
        return;
      }
      
      var ucode = JSON.parse(window.localStorage.user);
      if(row.PersonOfDutyCode!=ucode.sub && row.CreatedId!=ucode.sub)
      {
        this.$message({
          message: "当前用户非任务责任人或创建人，不能取消！",
          type: "info",
          duration: 10000
        });
        return;
      }

      this.$confirm('确认取消该专项会议任务吗？', '提示', {}).then(() => {
        let para = {
          taskId: row.Id,
          wantCancelUserCode: ucode.sub,
          wantCancelUserName: ucode.name
        };
        CancelTask(para).then((res) => {
          if (res.data.success) {
            this.$message({
              message: res.data.msg,
              type: 'success'
            });
            this.currentRow = null;
            this.meetings = []
            this.getProfessionalProjectMeetings();
          } else {
            this.$message({
              message: res.data.msg,
              type: 'error'
            });
          }
        });
      });
    },
    checkclose () {
      this.checkVisible = false;
      this.pValue1 = 0;
    },
    //双击查看详细信息
    checkInfo (row) {
      if (row.NoTask > 0) {
        return;
      }
      this.checkVisible = true;
      this.checkForm = row;
    },
    //指派人回调
    assigncallFunction (newdata) {
      this.needName = null;
      this.addForm.AssignCode = newdata[0];
      let para = { userId: newdata[0] };
      GetUserPostList(para).then((res) => {
        for (var i = 0; i < res.data.data.length; i++) {
          this.addForm.AssignName = res.data.data[0].name;
          this.needName = this.addForm.AssignName;
        }
      });
    },
    //责任人回调
    dutycallFunction (newdata) {
      this.addMultiForm.PersonOfDutyCode = newdata[0];
      let yearplanpara = { userCode: this.addMultiForm.PersonOfDutyCode };
      // GetYearPlanListByUserCode(yearplanpara).then((res) => {
      //   this.YearPlanTaskList = res.data.response;
      // });
      let para = { userId: newdata[0] };
      GetUserPostList(para).then((res) => {
        for (var i = 0; i < res.data.data.length; i++) {
          this.addMultiForm.PersonOfDuty = res.data.data[0].name;
        }
      });
    },
    //指令人回调
    ordercallFunction (newdata) {
      this.orderneedname = null;
      this.addForm.InstructUserCode = newdata[0];
      let para = { userId: newdata[0] };
      GetUserPostList(para).then((res) => {
        for (var i = 0; i < res.data.data.length; i++) {
          this.addForm.InstructUserName = res.data.data[0].name;
          this.orderneedname = this.addForm.InstructUserName;
        }
      });
    },
    //进度新增
    addpro () {
      this.addProgressVisible = true;
      this.addProgressForm.ProgressDescription = null;
      this.showminus = false;
      this.showplus = true;
      //this.provalue2 = this.provalue;
    },
    addopen () {
      if (this.progresscheck == false) {
        this.$message({
          message: "当前任务被指派人并未都完成被指派任务,故进度最大只能添加至95%！",
          type: "warning",
          duration: 10000
        });
      }
    },
    //进度新增提交
    checkSubmit () {
      var user = JSON.parse(window.localStorage.user);
      let para = {
        taskID: this.checkForm.Id,
        progressDescription: this.addProgressForm.ProgressDescription,
        progressValue: this.pValue2,
        filename: this.addProgressForm.FileName,
        fileaddress: this.addProgressForm.FileAddress,
        createdId: user.sub,
        createdBy: user.name
      };
      if (this.addProgressForm.ProgressDescription == null) {
        this.$message({
          message: '请填写进度内容',
          type: 'warning'
        });
      } else {
        this.Loadingstart();
        AddTaskProgress(para).then((res) => {
          if (res.data.success) {
            this.$message({
              message: res.data.msg,
              type: 'success'
            });
            this.Loadignend();
            let para = { taskId: this.checkForm.Id };
            QueryTaskProgressByTaskId(para).then((res) => {
              // this.tasks = res.data.response;
              var data = res.data.response;
              let formatData = new Array();
              for (var a = 0; a < data.length; a++) {
                var element = data[a];
                element.Files = [];
                if (element.FileName != null) {
                  var arr = element.FileName.split(",");
                  var arr2 = element.FileAddress.split(",");
                  var files = [];
                  for (var d = 0; d < arr.length; d++) {
                    var f = {};
                    f.FileName = arr[d];
                    f.FileAddress = arr2[d];
                    files.push(f);
                  }
                  element.Files = files;
                }
                formatData.push(element);
              }
              this.tasks = formatData;
              this.RefreshProgress(this.tasks);
              this.$nextTick(function () {
                var div = document.getElementsByClassName("professdiv2");
                if (div && 0 < div.length) {
                  div[0].scrollTop = div[0].scrollHeight;
                }
              })
            });

            this.addProgressVisible = false;
            this.getProfessionalProjectMeetings();

          } else {
            this.$message({
              message: res.data.msg,
              type: 'error'
            });
          }
        });
      }
    },
    RefreshProgress (tasksProgressRow) {
      var rowlength = tasksProgressRow.length;
      if (rowlength > 0) {
        this.pValue2 = tasksProgressRow[tasksProgressRow.length - 1].ProgressValue;
      } else {
        this.pValue2 = 0;
      }
      this.pValue1 = this.pValue2;
      if (this.pValue1 == 95 && this.progresscheck == false) {
        this.istaskpro = false;
      } else {
        this.istaskpro = true;
      }
    },
    proclose () {
      this.addProgressVisible = false;
      this.addProgressForm.ProgressDescription = null;
      this.pValue2 = this.pValue1;
      this.key = this.key + 1;
      //this.provalue2 = null;
    },
    tipMessage () {
      this.$message({
        message: '填写未完成，请继续填写',
        type: 'warning'
      });
    },
    clickclose () {
      this.$refs['addForm'].resetFields();
    },
    closeassign () {
      this.addForm.AssignName = null;
    },
    //获取历史信息
    historydata (historyItem) {
      this.addForm.MeetingName = historyItem;
      this.showhistory = false;
      this.$refs['meetinginput'].focus()
    },
    //输入框点击
    meetingNameblur () {
      if (this.historyList.length != 0) {
        this.showhistory = true;
      } else {
        this.showhistory = false;
      }
    },
    meetingNameblur1 () {
      this.showhistory = false;
    },
    meetingNameblur2 () {
      this.showhistory = false;
    },
    meetingNameblur3 () {
      this.showhistory = false;
    },
    /**
  * 加入历史搜索记录
  */
    setlocalHistory (val) {
      val = val.trim()
      let localHistory = localStorage.getItem('localHistory')
      if (localHistory === null) {
        localStorage.setItem('localHistory', val) // 若未设置过则直接设置本地存储
      } else {
        let localHistoryArray = localHistory.split('|').filter(item => item != val) // 删除搜索历史中与本次输出重复项
        if (localHistoryArray.length > 0) {
          localHistory = val + '|' + localHistoryArray.join('|') // 新增记录
        }
        if (localHistory.split('|').length > 5) { // 最大历史搜索记录10条
          localHistoryArray = localHistory.split('|')
          localHistoryArray.pop() // 删除最旧一项
          localHistory = localHistoryArray.join('|')
        }
        localStorage.setItem('localHistory', localHistory) // 存入本地
      }
    },
    /**
       * 清空历史搜索记录
       */
    clearHistory () {
      localStorage.removeItem('localHistory') // 清空搜索历史
      this.historyList = [];
      this.showhistory = false;
    },
    //多行新增
    insertmeeting () {
      this.multitype = "新增";
      this.addMultiFormVisible = true;
      var user = JSON.parse(window.localStorage.user);
      this.addMultiForm.PersonOfDuty = user.name;
      this.addMultiForm.PersonOfDutyCode = user.sub;
      let para = { userCode: user.sub };
      // GetYearPlanListByUserCode(para).then((res) => {
      //   this.YearPlanTaskList = res.data.response;
      // });
    },
    //多行编辑
    editmeeting (index, row) {
      this.multitype = "编辑";
      this.addMultiForm = row;
      this.multiindex = index;
      this.addMultiFormVisible = true;
      if(row.WorkDescription.indexOf('<')!=-1){
        this.descModeVal = true;
        this.$refs.editorDescElem.clean('');
        this.$refs.editorDescElem.append(row.WorkDescription);
      }else{
        this.descModeVal = false;
        this.simpleWorkDescription = row.WorkDescription;
      }
    },
    //多行提交
    addMultiSubmit () {
      // console.log(this.addMultiForm.WorkDescription)
      // return;
      this.$refs.addMultiForm.validate(valid => {
        if (valid) {
          let para = Object.assign({}, this.addMultiForm);
          if (this.multitype == "新增") {
            this.meetingList.push(para);
          } else {
            this.$set(this.meetingList, this.multiindex, para);
          }
          this.$refs.addMultiForm.resetFields();
          this.addMultiFormVisible = false;
          this.multitype = null;
          this.multiindex = null;
          this.addMultiForm.Tag = null;
          this.addMultiForm.TagName = null;
          this.key += 1;
        }
      })
    },
    //多行删除
    deletemeeting (row) {
      this.$confirm("确认要删除吗？", "提示", {
        type: "warning"
      }).then(_ => {
        this.meetingList.splice(
          this.meetingList.indexOf(row),
          1
        );
      });
    },
    MultitableRowClassName ({ row, rowIndex }) {
      if (rowIndex % 2 !== 0) {
        return 'warning-row';
      }
      return '';
    },
    Loadingstart () {
      this.loading = Loading.service({
        lock: true,
        text: '加载中...',
        background: 'rgba(0,0,0,0.1)'
      })
    },
    Loadignend () {
      this.loading.close();
    }
  },
  mounted () {
    this.getProfessionalProjectMeetings();
    var user = JSON.parse(window.localStorage.user);
    this.localuser = user.name;
    let routers = window.localStorage.router
      ? JSON.parse(window.localStorage.router)
      : [];
    //第一种写法，每个页面都需要写方法，但是可以做特性化处理
    // this.getButtonList(routers);

    //第二种写法，封装到 permissionRouter.js 中
    let buttons = window.localStorage.buttList ? JSON.parse(window.localStorage.buttList) : [];
    this.buttonList = getButtonList(this.$route.path, routers, buttons);
    if (localStorage.getItem('localHistory') !== null) {
      this.historyList = localStorage.getItem('localHistory').split('|')
    }
  }

}
</script>
<style scoped>
.history .record {
  display: inline-block;
  padding: 3px 10px;
  font-size: 12px;
  background: #ffffff;
  margin-right: 10px;
  margin-top: 5px;
  cursor: pointer;
}

.professdiv,
.professcard {
  height: calc(100vh - 220px);
}
.professdiv2 {
  max-height: calc(100vh - 350px);
  overflow: auto;
  padding-left: 2px;
}
.active {
  background-color: #00000014;
}
.eldropdownitem,
.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
}
</style>